.completedReports-search {
    padding-top: 0.5rem;
}

.completedReports-search .MuiInputBase-input {
    background-color: white;
}

.completedReports-search .MuiTextField-root {
    background-color: white;
}

.completedReports-search .CompletedReportsSearch-chip {
    margin: 0.25rem;
}