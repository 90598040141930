.supplier-search {
    padding-top: 0.5rem;
}

.supplier-search .MuiInputBase-input {
    background-color: white;
}

.supplier-search .MuiTextField-root {
    background-color: white;
}